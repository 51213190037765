<template>
  <div>
    <div class="body-box">
      <div style="width: 200px;float:left;">
        <el-menu
            :default-active="selectName"
            class="el-menu-vertical-demo"
            style="text-align: center"
            @select="handleOpen">
          <el-menu-item index="1">
            <span slot="title">签到统计</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="agricul_right ib">
        <div v-if="selectName == 1">
          <div class="head-name-title" style="margin-left: 20px">签到统计</div>
          <div style="margin-left: 20px;margin-top: 30px">日期:
            <el-date-picker
                v-model="signDay"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
            <el-radio-group v-model="radio" @change="radioChange" style="margin-left: 20px">
              <el-radio :label="0">签到</el-radio>
              <el-radio :label="1">签退</el-radio>
            </el-radio-group>
            <el-button type="success" icon="el-icon-search" style="margin-left: 30px;background: #009400" @click="serchButon">搜索</el-button>
          </div>
          <div style="margin: 30px 0 30px 20px  ">
            <el-table
                :data="tableData"
                border
                style="width: 950px;font-size: 14px"
                :row-class-name="tableRowClassName">
              <el-table-column
                  prop="id"
                  label="序号">
              </el-table-column>
              <el-table-column
                  prop="userName"
                  label="姓名"
              >
              </el-table-column>

              <el-table-column
                  prop="remark"
                  label="签到类型">
              </el-table-column>
              <el-table-column
                  prop="statusName"
                  label="签到状态">
              </el-table-column>
              <el-table-column
                  prop="signTime"
                  label="签到时间">
              </el-table-column>

            </el-table>
            <el-pagination
                style="margin-top: 10px"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
          </div>

        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      selectName:  localStorage.getItem('selectIndex'),
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      signDay:'',
      remark:'',
      radio:'',
      userId:JSON.parse(localStorage.getItem('user_nongye')).userId,
      tableData: []
    }
  },
mounted() {
  this.getDataList()
},
  methods: {
    radioChange(res){
      console.log(res)
      if (res === 0){
        this.remark = '签到'
      }else{
        this.remark = '签退'
      }

    },
    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
        "signDay": this.signDay ,
        "remark": this.remark,
      }
      this.$api.post('signRecord/getList', params, res => {
        console.log(res, '签到统计数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.selectName = key
    },
    serchButon(){
      this.getDataList()
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
  }
}
</script>

<style scoped>
.body-box {
  margin-top: 20px;
}

.ib {
  /*display: inline-block;*/
}

.agricul_right {
  margin-left: 10px;
  width: 990px;

  background: #FFFFFF;
  float: left;
}

.clear {
  clear: both
}

.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
